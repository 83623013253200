<template>
  <div
    :class="[{
      'is-hidden-attribute': hiddenAttribute,
      'uses-template': usesPlanTemplates,
    }, 'rate-entry']"
    :data-test="`${rate.name?.toLowerCase() ?? 'generic rate'} entry`"
  >
    <!-- Header -->
    <h5>
      {{ rate.name }}
      <AppButton
        type="secondary"
        class="hide-button"
        :icon="`fa-solid ${!hiddenAttribute ? 'fa-eye' : 'fa-eye-slash'}`"
        data-test="toggle eye"
        size="icon"
        @click="disableAttribute(hiddenAttribute)"
      />
    </h5>
    <!-- Grouped Values -->
    <div
      v-for="(group, groupIndex) in rate.grouped_values"
      :key="group.ids[0]"
    >
      <ElForm
        ref="rate-form"
        :model="group"
      >
        <div
          v-if="group.firstInContainer || hiddenAttribute"
          class="row"
        >
          <label>Rate basis:</label>
          <ElFormItem
            v-if="!hiddenAttribute && !readOnlyRate"
            prop="rate_basis"
            :rules="{ required: true, message: 'Rate basis is required.', trigger: 'none' }"
          >
            <ElSelect
              v-if="!hiddenAttribute"
              v-model="group.parent_rate_basis"
              class="rate-basis-select"
              size="medium"
              placeholder="Select"
              data-test="rate basis select"
              :disabled="missingContainers"
              @change="handleUpdateRateBasis($event)"
            >
              <ElOption
                v-for="rateBasis in rateBasisOptions"
                :key="rateBasis.value"
                :label="rateBasis.label"
                :value="rateBasis.value"
                :data-test="`${rateBasis.label?.toLowerCase() ?? 'unnamed'} rate basis option`"
              />
            </ElSelect>
          </ElFormItem>
          <span
            v-else-if="readOnlyRate"
            class="rate-basis-read-only"
          >
            <template v-if="hiddenAttribute">
              —
            </template>
            <template v-else>
              {{ getRateBasisValue(group.rate_basis) }}
            </template>
          </span>
          <ElSelect
            v-else
            value=""
            :disabled="true"
            class="rate-basis-select"
            size="medium"
            placeholder="Select"
          >
            <ElOption value="" />
          </ElSelect>
          <TfPopover
            v-if="!readOnlyRate"
            v-model="group.popoverVisible"
            placement="left"
            :offset="-40"
            :visible-arrow="false"
            :append-to-body="false"
            popper-class="attribute-more-popover"
            width="122"
            trigger="click"
          >
            <!-- TODO LC-563 -->
            <AppButton
              slot="reference"
              class="ellipsis-button"
              type="secondary"
              icon="fa-solid fa-ellipsis-v"
              size="icon"
              text="More"
              data-test="more button"
            />
            <AppButton
              class="close-more-button"
              type="secondary"
              icon="fa-solid fa-times"
              size="icon"
              text="Close"
              @click="group.popoverVisible = !group.popoverVisible"
            />
            <div class="button-group">
              <AppButton
                type="secondary"
                icon="fa-solid fa-plus"
                size="text-small"
                text="Add row"
                @click="onAddGroupClick()"
              />
              <AppButton
                :is-disabled="isDeleteDisabled(group, groupIndex)"
                type="secondary"
                icon="fa-solid fa-trash-alt"
                size="text-small"
                text="Delete row"
                @click="deleteRow(group)"
              />
            </div>
          </TfPopover>
          <AppButton
            v-if="readOnlyRate"
            class="pencil-button"
            type="secondary"
            icon="fa-solid fa-pencil"
            size="icon"
            text="Edit"
            @click="readOnlyRate = false"
          />
        </div>
        <div
          v-if="group.firstInContainer || hiddenAttribute"
          class="row"
        >
          <label
            v-if="containerTypeSelection && containerTypeSelection.toLowerCase() === 'class'"
            v-text="'Class(es)'"
          />
          <label
            v-else-if="containerTypeSelection && containerTypeSelection.toLowerCase() === 'plan'"
            v-text="'Plan(s)'"
          />
          <ElFormItem
            v-if="!hiddenAttribute && !readOnlyRate"
            prop="project_products_container_ids"
            :rules="{ required: true, message: 'Containers are required.', trigger: 'none' }"
          >
            <TfMultiSelect
              :key="`multi-select-${group.ids[0]}`"
              v-model="group.project_products_container_ids"
              data-test="multi select"
              :append-to-body="false"
              :label="isClassBased ? 'name' : 'description'"
              :options="getAvailableContainers(group, groupIndex)"
              :total-options-available="currentContainers.length"
              :disable-click="missingContainers"
              value-key="id"
              empty-text="No containers available"
              popover-trigger="click"
              @change="onContainerChange(group, $event)"
            />
          </ElFormItem>
          <span
            v-else-if="readOnlyRate"
            class="container-read-only-label"
          >
            <template v-if="hiddenAttribute">
              —
            </template>
            <template v-else-if="containerTypeSelection">
              {{ getContainersDisplayValue(group.project_products_container_ids, currentContainers, containerTypeSelection.toLowerCase() === 'class') }}
            </template>
          </span>
          <ElSelect
            v-else
            value=""
            :disabled="true"
            class="disabled-container-select"
            size="medium"
            placeholder="Select"
          >
            <ElOption value="" />
          </ElSelect>
        </div>
        <table
          class="table table-child"
        >
          <thead v-if="group.firstInContainer || hiddenAttribute">
            <tr>
              <th>Rate tier</th>
              <th>
                Rate type
                <AppButton
                  v-if="!readOnlyRate"
                  type="secondary"
                  size="icon"
                  icon="fa-solid fa-calculator"
                  text="Rate basis override"
                  data-test="rate basis override"
                  @click="onRateBasisOverrideClick(group)"
                />
              </th>
              <th>
                Value
              </th>
            </tr>
          </thead>
          <tbody v-if="!hiddenAttribute">
            <tr
              v-for="(value, valueIndex) in group.values"
              :key="value.id"
              :class="{ 'has-rate-basis': group.rateBasisOverride }"
              :data-test="`${group.tier_subtype_name?.toLowerCase() ?? 'unnamed'} rate row`"
            >
              <td>
                <ElSelect
                  v-if="(group.firstInContainer && valueIndex === 0) && !readOnlyRate"
                  v-model="group.tier_group_id"
                  data-test="rate tier"
                  class="subtype-select"
                  :disabled="!availableRateTierGroups.length || missingContainers"
                  size="mini"
                  placeholder="None"
                  @change="onTierGroupChange($event, group)"
                >
                  <ElOption
                    label="None"
                    :value="null"
                    data-test="none"
                  />
                  <ElOption
                    v-for="availableTierGroup in availableRateTierGroups"
                    :key="availableTierGroup.tier_group_id"
                    :label="availableTierGroup.tier_group_name"
                    :value="availableTierGroup.tier_group_id"
                    :data-test="availableTierGroup.tier_group_name.toLowerCase()"
                  />
                </ElSelect>
                <span
                  v-else-if="(group.firstInContainer && valueIndex === 0) && readOnlyRate"
                  class="subtype-read-only-label"
                >
                  <!-- Potentially ask Patti about this...because long titles mess up the spacing -->
                  {{ group && group.tier_group_id && availableRateTierGroups.length
                    ? availableRateTierGroups.find(tierGroup => tierGroup.tier_group_id === group.tier_group_id).tier_group_name
                    : 'None'
                  }}
                </span>
                <label
                  v-if="group.tier_subtype_name && valueIndex === 0"
                  class="subtype-name"
                  :data-test="`tier group: ${group.tier_subtype_name.toLowerCase()}`"
                >{{ group.tier_subtype_name }} </label>
              </td>
              <td class="rate-type-cell">
                <ElSelect
                  v-if="valueIndex === 0 && !readOnlyRate"
                  v-model="group.type"
                  data-test="rate type"
                  :class="[
                    { 'padding-top': group.firstInContainer && group.tier_group_id && valueIndex === 0 },
                    'rate-type-select',
                  ]"
                  :disabled="missingContainers"
                  size="mini"
                  @change="onRateTypeChange(group, $event)"
                >
                  <ElOption
                    data-test="composite rate value"
                    label="Composite"
                    value="CompositeRateValue"
                  />
                  <ElOption
                    data-test="age banded rate value"
                    label="Age banded"
                    value="AgeBandedRateValue"
                  />
                </ElSelect>
                <span
                  v-if="valueIndex === 0 && readOnlyRate"
                  class="read-only-value"
                >
                  <span v-if="group.type === 'CompositeRateValue'">
                    Composite
                  </span>
                  <span v-else-if="group.type === 'AgeBandedRateValue'">
                    Age banded
                  </span>
                </span>
                <div
                  v-if="group.rateBasisOverride && valueIndex === 0"
                  class="rate-basis-container"
                >
                  <ElSelect
                    v-if="!readOnlyRate"
                    v-model="group.rate_basis"
                    size="mini"
                    placeholder="Rate basis"
                    :data-test="`${group.type ?? 'unnamed'} rate basis`"
                    @change="handleUpdateRateBasis($event, group)"
                  >
                    <ElOption
                      v-for="rateBasis in rateBasisOptions"
                      :key="rateBasis.value"
                      :label="rateBasis.label"
                      :value="rateBasis.value"
                      :data-test="`${rateBasis.label?.toLowerCase() ?? ''} rate basis option`"
                    />
                  </ElSelect>
                  <AppButton
                    v-if="!readOnlyRate"
                    type="decline"
                    icon="fa-solid fa-times"
                    size="icon"
                    text="Cancel"
                    :data-test="`${group.type ?? 'unnamed'} rate basis cancel`"
                    @click="hideRateBasis(group)"
                  />
                  <span
                    v-if="readOnlyRate"
                    class="read-only-value"
                  >
                    {{ getRateBasisValue(group.rate_basis) }}
                  </span>
                </div>
                <label v-if="group.values[0].label !== 'composite'">{{ value.display_label }}</label>
              </td>
              <td :class="{ 'align-center': group.rateBasisOverride && valueIndex === 0 && group.firstInContainer }">
                <!-- {{ valueIndex === 0 && group.tier_group_id }} -->
                <ElFormItem
                  :prop="`values.${valueIndex}.value`"
                  :class="[
                    { 'padding-top': valueIndex === 0 && (group.values.length > 1 || group.rateBasisOverride) },
                    { 'padding-top-with-error': valueIndex === 0 && group.tier_group_id && groupIndex === 0 },
                    { 'double-padding-top': (group.firstInContainer && group.tier_group_id && valueIndex === 0 && group.values.length > 1) || (valueIndex === 0 && group.rateBasisOverride && group.values.length > 1) },
                  ]"
                  :rules="[
                    { required: true, message: 'Value is required.', trigger: 'none' },
                    { validator: validateNumericRate, trigger: 'blur' },
                  ]"
                >
                  <ElInput
                    v-if="!readOnlyRate"
                    v-model="value.value"
                    :disabled="missingContainers"
                    class="rate-value-input"
                    data-test="rate value input"
                    placeholder="Enter value"
                    size="mini"
                    @blur="onRateBlur(group)"
                    @change="onValuesChange(group)"
                  />
                  <span
                    v-else
                    class="read-only-value"
                  >
                    {{ value.value }}
                  </span>
                </ElFormItem>
              </td>
            </tr>
          </tbody>
          <tbody v-if="hiddenAttribute">
            <td>
              <ElSelect
                v-if="!readOnlyRate"
                class="subtype-select"
                :disabled="true"
                value=""
                size="mini"
                placeholder="None"
              >
                <ElOption
                  label="None"
                  :value="null"
                />
              </ElSelect>
              <span
                v-else
                class="subtype-read-only-label"
              >
                —
              </span>
            </td>
            <td>
              <ElSelect
                v-if="!readOnlyRate"
                class="rate-type-select"
                :disabled="true"
                value=""
                size="mini"
                placeholder="None"
              >
                <ElOption
                  label="Composite"
                  value="CompositeRateValue"
                />
              </ElSelect>
              <span
                v-else
                class="read-only-value"
              >
                —
              </span>
            </td>
            <td>
              <ElInput
                v-if="!readOnlyRate"
                placeholder="Enter value"
                value=""
                :disabled="true"
                class="rate-value-input"
                size="mini"
              />
              <span
                v-else
                class="read-only-value"
              >
                —
              </span>
            </td>
          </tbody>
        </table>
      </ElForm>
    </div>
    <!-- End Grouped Values -->
  </div>
</template>

<script>
  import { reusableRateBasisOptions } from '@watchtowerbenefits/es-utils-public';

  import {
    cloneDeep, find, isEqual, uniqWith,
  } from 'lodash';
  import ProductService from '@/services/product.js';
  import ContainerUtil from '@/utils/containers.js';
  import MathUtil from '@/utils/math.js';
  import { mapActions, mapState } from 'pinia';
  import { useProjectStore } from '@/stores/project.js';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { useProductContainersStore } from '@/stores/productContainers.js';
  import { useProductStructuresStore } from '@/stores/productStructures.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';

  /**
   * Individual Component per attribute for rate entry
   *
   * @vuedoc
   * @exports RateEntryGroup
   * @category Components
   */
  export default {
    name: 'RateEntryGroup',
    inject: ['adminShortcut'],
    props: {
      rateAttribute: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        getContainersDisplayValue: ContainerUtil.getContainersDisplayValue,
        rate: {},
        hiddenAttribute: false,
        readOnlyRate: false,
        ignoreContainerChange: false,
        // TODO: Possibly move this into a reusable utility
        ageBandedValues: [
          { label: 'age_0_19', displayLabel: '0 - 19' },
          { label: 'age_20_24', displayLabel: '20 - 24' },
          { label: 'age_25_29', displayLabel: '25 - 29' },
          { label: 'age_30_34', displayLabel: '30 - 34' },
          { label: 'age_35_39', displayLabel: '35 - 39' },
          { label: 'age_40_44', displayLabel: '40 - 44' },
          { label: 'age_45_49', displayLabel: '45 - 49' },
          { label: 'age_50_54', displayLabel: '50 - 54' },
          { label: 'age_55_59', displayLabel: '55 - 59' },
          { label: 'age_60_64', displayLabel: '60 - 64' },
          { label: 'age_65_69', displayLabel: '65 - 69' },
          { label: 'age_70_74', displayLabel: '70 - 74' },
          { label: 'age_75_79', displayLabel: '75 - 79' },
          { label: 'age_80_plus', displayLabel: '80+' },
        ],
      };
    },
    computed: {
      ...mapState(useProductSelectionsStore, [
        'selectedProductId',
        'selectedProductTypeId',
      ]),
      ...mapState(useProductStructuresStore, ['availableRateTierGroups']),
      ...mapState(useProductContainersStore, [
        'containerTypeSelection',
        'currentContainers',
        'isClassBased',
        'missingContainers',
      ]),
      ...mapState(useProjectProductStore, ['usesPlanTemplates']),
      ...mapState(useProjectStore, ['readOnlyMode']),
      /**
       * Grab the rate basis options from the shared repo (for this product type)
       *
       * @returns {Array}
       */
      rateBasisOptions() {
        return reusableRateBasisOptions(this.selectedProductTypeId);
      },
    },
    watch: {
      /**
       * If we add a new container and previously "all" containers were selected we should add the new one too
       *
       * @param {Array} newContainers
       * @param {Array} oldContainers
       */
      currentContainers(newContainers, oldContainers) {
        const newContainerArray = newContainers.map((container) => container.id);

        this.rate.grouped_values.forEach((groupValue, groupValueIndex) => {
          if (groupValue.project_products_container_ids && groupValue.project_products_container_ids.length === oldContainers.length) {
            this.rate.grouped_values[groupValueIndex].project_products_container_ids = newContainerArray;
          }
        });
      },
      /**
       * assign readOnlyRate to readOnlyMode value
       */
      readOnlyMode() {
        this.readOnlyRate = this.readOnlyMode;
      },
    },
    created() {
      // clone the prop so we can don't mutate the pinia store as we change it
      this.rate = cloneDeep(this.rateAttribute);
      let readOnlyRate = this.readOnlyMode;

      // if there are no groups we know it's a "hidden" attribute
      if (this.rate.grouped_values.length) {
        this.rate.grouped_values.forEach((group, groupIndex) => {
          this.$set(group, 'parent_rate_basis', group.rate_basis || this.rateBasisOptions[0].value);
          const formattedGroup = this.rate.grouped_values[groupIndex];
          let rateBasisOverride = false;

          // If the rate_basis doesn't match the first groups I assume it was "overridden"
          if (group.rate_basis !== this.rate.grouped_values[0].rate_basis) {
            rateBasisOverride = true;
          }
          if (!group.rate_basis) {
            formattedGroup.rate_basis = this.rateBasisOptions[0].value;
          }
          if (!group.project_products_container_ids.length) {
            readOnlyRate = false;
          }
          formattedGroup.values.forEach((groupValue, groupValueIndex) => {
            if (groupValue.value) {
              formattedGroup.values[groupValueIndex].value = parseFloat(groupValue.value).toFixed(3);
              if (groupValue.value < 1 && groupValue.value.indexOf('.') === 0) {
                formattedGroup.values[groupValueIndex].value = `0${groupValue.value}`;
              }
            } else {
              readOnlyRate = false;
            }
          });
          this.$set(group, 'rateBasisOverride', rateBasisOverride);
        });

        this.readOnlyRate = readOnlyRate;
        this.sortGroupsAndMarkFirst();
      } else {
        // even hidden attributes need a group (with an id for the v-for :key)
        this.rate.grouped_values = [{ ids: [MathUtil.getRandomNumber()] }];
        this.hiddenAttribute = true;
      }

      // B&B bypass: will use disableAttribute to delete attribute values
      if (this.adminShortcut && !this.hiddenAttribute) {
        this.disableAttribute(false);
      }
    },
    methods: {
      ...mapActions(useProjectProductStore, ['updateProjectProductsValidation']),
      /**
       * validate this rateEntryGroup's rates and perform a callback.
       *
       * @returns {Promise}
       */
      validateRates() {
        return new Promise((resolve) => {
          const intervalMethod = setInterval(() => {
            if (!this.updateInProgress) {
              const forms = this.$refs['rate-form'];
              let errorsFound = 0;

              clearInterval(intervalMethod);
              if (forms.length) {
                forms.forEach((form) => {
                  form.validate((valid, errorObject) => {
                    if (!valid) {
                      errorsFound += Object.keys(errorObject).length;
                    }
                  });
                });
              }
              resolve(errorsFound);
            }
          }, 300);
        });
      },
      /**
       * gets the rate basis value label if given a string
       *
       * @param {string} rateBasisValue
       * @returns {string} the label or blank
       */
      getRateBasisValue(rateBasisValue) {
        if (rateBasisValue) {
          const value = find(this.rateBasisOptions, (rateBasis) => rateBasis.value === rateBasisValue);

          return value.label;
        }

        return '';
      },
      /**
       * calls an error if value isn't a number
       *
       * @param {object} rule
       * @param {string} value
       * @param {Function} callback
       */
      validateNumericRate(rule, value, callback) {
        if (Number.isNaN(value)) {
          callback(new Error('Rates must be numeric.'));
        } else {
          callback();
        }
      },
      /**
       * Return containers not currently used in other groups
       *
       * @param {object} group
       * @param {number} groupIndex
       * @returns {Array}
       */
      getAvailableContainers(group, groupIndex) {
        let inUseContainers = [];
        let availableContainers = cloneDeep(this.currentContainers);

        this.rate.grouped_values.forEach((currentGroup, index) => {
          if (groupIndex !== index && (!group.tier_group_id || (group.tier_group_id !== currentGroup.tier_group_id))) {
            inUseContainers = inUseContainers.concat(currentGroup.project_products_container_ids);
          }
        });
        availableContainers = availableContainers.filter((container) => inUseContainers.indexOf(container.id) === -1);

        return availableContainers;
      },
      /**
       * When a user clicks the calculator button we need to show individual rate basis dropdowns for groups with the same containers
       *
       * @param {object} group
       */
      onRateBasisOverrideClick(group) {
        // grab the project_products_container_ids and search the groups for others that use these classes
        const containerArray = group.project_products_container_ids;

        this.rate.grouped_values.forEach((currentGroup, currentGroupIndex) => {
          if (isEqual(containerArray, currentGroup.project_products_container_ids)) {
            this.rate.grouped_values[currentGroupIndex].rateBasisOverride = !currentGroup.rateBasisOverride;
          }
        });
      },
      /**
       * When a user clicks the 'x' next to the individual rate basis select we hide the select and revert back the rate basis
       *
       * @param {object} group
       */
      hideRateBasis(group) {
        /* TODO RLH - Revisit to identify if we can make a copy of the updatedGroup without breaking things
      Current code appeases no-param-reassign Linter rule, but is still passing a reference */
        const updatedGroup = group;

        updatedGroup.rateBasisOverride = !group.rateBasisOverride;
        updatedGroup.rate_basis = this.rate.grouped_values[0].rate_basis;
        this.updateRateValues(updatedGroup);
      },
      /**
       * Sort all groups to be with matching containers
       * Then sort rates by their subtype position (if applicable)
       * Then we mark the first the rate in each group as the first and concatenate them back into one array
       */
      sortGroupsAndMarkFirst() {
        // take all the rates/groups and sort them so groups with the same container are together
        const groupByProjectProductsContainers = (groups) => {
          groups.forEach((group) => {
            group.project_products_container_ids.sort();
          });
          const sortedGroups = groups.reduce((accumulator, item) => {
            /* eslint-disable no-param-reassign */
            (accumulator[item.project_products_container_ids] = accumulator[item.project_products_container_ids] || []).push(item);

            /* eslint-enable no-param-reassign */
            return accumulator;
          }, {});

          // the above method groups the results in an object with a key (of the container ids)
          // the following strips out those keys and returns an array instead of an object
          return Object.keys(sortedGroups).map((key) => sortedGroups[key]);
        };
        let groups = groupByProjectProductsContainers(this.rate.grouped_values);

        // Run through each group and sort their subtypes (if applicable)
        groups = groups.map((group) => {
          let returnGroup = [
            ...group,
          ];
          const firstRate = group[0];
          const hasTieredRates = firstRate.tier_group_id !== null;

          if (hasTieredRates) {
            const tierGroup = this.availableRateTierGroups.find((availableRateTierGroup) => availableRateTierGroup.tier_group_id === firstRate.tier_group_id);
            const subtypes = tierGroup.tier_subtypes;

            returnGroup = returnGroup.map((rate) => {
              const subtype = subtypes.find((currentSubtype) => rate.tier_subtype_id === currentSubtype.subtype_id);

              return {
                ...rate,
                subtype_position: subtype.subtype_position,
              };
            });
            returnGroup.sort((a, b) => a.subtype_position - b.subtype_position);
          }
          // set the first rate to have "firstInContainer" so we know to hide the dropdowns
          returnGroup[0].firstInContainer = true;

          return returnGroup;
        });

        // flatten all the groups back together (in the right order)
        this.$set(this.rate, 'grouped_values', groups.flat());
      },
      /**
       * When a group changes subtypes we need to remove the groups with those containers and replace them
       *
       * @param {object} newTierGroupId
       * @param {number} group
       */
      onTierGroupChange(newTierGroupId, group) {
        const tierGroup = this.availableRateTierGroups.find((availableRateTierGroup) => availableRateTierGroup.tier_group_id === newTierGroupId);
        let newSubtypes = [];
        const containerArray = group.project_products_container_ids;
        const rateBasis = group.rate_basis;

        this.ignoreContainerChange = true;
        this.rate.grouped_values = this.rate.grouped_values.filter((groupedValue) => (
          !isEqual(containerArray, groupedValue.project_products_container_ids)
        ));

        ProductService
          .deleteRateValues(this.selectedProductId, this.rate.id, containerArray)
          .then(() => {
            // if this is in a tier group we add a group for each new subtype
            if (tierGroup) {
              newSubtypes = [...tierGroup.tier_subtypes];
              // Sort the subtype by position...I don't know if this 100% necessary but just being safe
              newSubtypes = newSubtypes.sort((a, b) => a.subtype_position - b.subtype_position);
              newSubtypes.forEach((subtype) => {
                this.addGroup(containerArray, newTierGroupId, subtype.subtype_id, subtype.subtype_name, rateBasis);
              });
            } else {
              // otherwise we add just one group because the user selected 'none'
              this.addGroup(containerArray, newTierGroupId, null, null, rateBasis);
            }
            // now lets save all the values so if they refresh the format stays the same
            this.rate.grouped_values.forEach((rateGroup) => {
              this.updateRateValues(rateGroup);
            });
            this.sortGroupsAndMarkFirst();
            this.ignoreContainerChange = false;
          })
          .catch(() => {
            this.displayToast({
              message: 'There was an error deleting the rate values.',
            });
          });
      },
      /**
       * Check if we should allow the user to delete this group
       *
       * @param {object} group
       * @param {number} groupIndex
       * @returns {boolean}
       */
      isDeleteDisabled(group, groupIndex) {
        return groupIndex === 0;
      },
      /**
       * Toggling on and off whether or not this attribute is disabled/hidden
       *
       * @param {boolean} disabled
       */
      disableAttribute(disabled) {
        if (disabled) {
          this.rate.grouped_values = [];
          const containerArray = this.currentContainers.map((container) => container.id);

          this.addGroup(containerArray).then(() => {
            this.sortGroupsAndMarkFirst();
            const group = this.rate.grouped_values[0];

            this.updateRateValues(group);
            this.hiddenAttribute = false;
            this.readOnlyRate = false;
          })
            .catch(() => {
              this.displayToast({
                message: 'There was an error adding the group.',
              });
            });
        } else {
          this
            .deleteRateValues()
            .then(() => {
              this.rate.grouped_values = [{ ids: [MathUtil.getRandomNumber()] }];
              this.hiddenAttribute = true;
              this.readOnlyRate = false;
              this.updateProjectProductsValidation({
                productId: this.selectedProductId,
                validRateStructure: false,
              });
            })
            .catch(() => {
              this.displayToast({
                message: 'There was an error deleting the rate values.',
              });
            });
        }
      },
      /**
       * Delete all the values associated with this rate
       *
       * @returns {Promise}
       */
      deleteRateValues() {
        const containerArray = this.currentContainers.map((container) => container.id);

        return ProductService.deleteRateValues(this.selectedProductId, this.rate.id, containerArray);
      },
      /**
       * When a user changes the containers on a group we find the ones that were removed and delete them
       * Then we update the group with the new containers and add a new blank group if we need one
       *
       * @param {object} group
       * @param {Array} containers
       */
      onContainerChange(group, containers) {
        if (this.ignoreContainerChange) {
          return;
        }
        let containersArray = [];
        let groupsWithNoContainers = 0;
        const totalContainersNeeded = this.currentContainers.length;
        const removedItems = containers.oldValue.filter((e) => containers.value.indexOf(e) < 0);
        const newItems = containers.value.filter((e) => containers.oldValue.indexOf(e) < 0);

        this.rate.grouped_values.forEach((groupedValue) => {
          if (!groupedValue.project_products_container_ids.length) {
            groupsWithNoContainers += 1;
          }
          containersArray = containersArray.concat(group.project_products_container_ids);
        });
        containersArray = uniqWith(containersArray, isEqual);

        // delete any values that were removed from the change
        if (removedItems.length) {
          ProductService.deleteRateValues(this.selectedProductId, this.rate.id, removedItems);
          if (group.tier_group_id) {
            // the item removed was part of a tier group, meaning there was probably a sibling subtype that needs to be removed too
            this.rate.grouped_values = this.rate.grouped_values.map((iteratedGroup) => {
              let containerIds;

              if (isEqual(containers.oldValue, iteratedGroup.project_products_container_ids)) {
                containerIds = iteratedGroup.project_products_container_ids.filter((containerId) => containerId !== removedItems[0]);
              }

              return {
                ...iteratedGroup,
                project_products_container_ids: containerIds || iteratedGroup.project_products_container_ids,
              };
            });
          }
        }

        if (newItems.length && group.project_products_container_ids.length) {
          const tierGroupId = group.tier_group_id;

          this.rate.grouped_values.forEach((groupValue, groupValueIndex) => {
            if (containers.oldValue.length && isEqual(containers.oldValue, groupValue.project_products_container_ids)) {
              // this group was using the same the containers and now needs to updated as well
              this.rate.grouped_values[groupValueIndex].project_products_container_ids = containers.value;
            }
            if (groupValue.tier_group_id === tierGroupId) {
              this.updateRateValues(groupValue);
            }
          });
        }

        if (removedItems.length && !groupsWithNoContainers && (containersArray.length < totalContainersNeeded)) {
          this.addGroup(removedItems).then(() => {
            this.sortGroupsAndMarkFirst();
          })
            .catch(() => {
              this.displayToast({
                message: 'There was an error adding the group.',
              });
            });
        }

        if (containersArray.length === this.currentContainers.length) {
          this.removeEmptyGroups();
          this.sortGroupsAndMarkFirst();
        }

        this.$emit('resetValidation');
      },
      /**
       * This is called when all containers are already taken, so we remove the empty groups
       */
      removeEmptyGroups() {
        this.rate.grouped_values = this.rate.grouped_values.filter((group) => group.project_products_container_ids.length);
      },
      /**
       * When a user clicks the 'add row' button
       */
      onAddGroupClick() {
        this.addGroup().then(() => {
          this.sortGroupsAndMarkFirst();
          this.$emit('resetValidation');
        })
          .catch(() => {
            this.displayToast({
              message: 'There was an error adding the group.',
            });
          });
      },
      /**
       * Create a new group object and add it to the rate.grouped_values array
       *
       * @param {Array} containerArray
       * @param {number} tieGroupId
       * @param {number} tierSubtypeId
       * @param {string} tierSubtypeName
       * @param {string} rateBasis
       * @returns {Promise}
       */
      addGroup(containerArray = [], tieGroupId = null, tierSubtypeId = null, tierSubtypeName = null, rateBasis = null) {
        return new Promise((resolve) => {
          const newValue = this.createNewRateValue('composite', 'Composite');
          const newGroup = {
            ids: [MathUtil.getRandomNumber()],
            monthly_premium: null,
            project_products_container_ids: containerArray,
            rate_basis: !rateBasis ? this.rateBasisOptions[0].value : rateBasis,
            parent_rate_basis: !rateBasis ? this.rateBasisOptions[0].value : rateBasis,
            tier_group_id: tieGroupId,
            tier_subtype_id: tierSubtypeId,
            tier_subtype_name: tierSubtypeName,
            type: 'CompositeRateValue',
            values: [newValue],
            rateBasisOverride: false,
          };

          this.rate.grouped_values.push(newGroup);
          this.$emit('resetValidation');
          resolve();
        });
      },
      /**
       * Delete the rate values associated to this "rows" containers
       *
       * @param {object} group
       */
      deleteRow(group) {
        const containerArray = group.project_products_container_ids;

        ProductService
          .deleteRateValues(this.selectedProductId, this.rate.id, containerArray)
          .then(() => {
            this.rate.grouped_values = this.rate.grouped_values.filter((groupValue) => !isEqual(containerArray, groupValue.project_products_container_ids));
            this.$emit('resetValidation');
          })
          .catch(() => {
            this.displayToast({
              message: 'There was an error deleting the rate values.',
            });
          });
      },
      /**
       * Handle the blur of input fields by running through the values and formatting them
       *
       * @param {object} group
       * @returns {Array}
       */
      onRateBlur(group) {
        this.$emit('resetValidation');

        return group.values.map((value) => this.formatValues(value));
      },
      /**
       * Format values by removing the $ and making sure we keep decimals to the thousandth
       *
       * @param {object} value
       * @returns {string}
       */
      formatValues(value) {
        const pattern = /[0-9]+(\.[0-9][0-9][0-9])$/;
        const updatedValue = value;

        if (typeof updatedValue.value === 'string') {
          updatedValue.value = updatedValue.value.replace(/\$/g, '');
        }
        let currentValue = updatedValue.value;

        if (currentValue && !Number.isNaN(currentValue)) {
          currentValue = parseFloat(currentValue).toFixed(3);
          if (currentValue < 1 && currentValue.indexOf('.') === 0) {
            currentValue = `0${currentValue}`;
          }
        }
        const validFormat = pattern.test(currentValue);

        if (validFormat) {
          updatedValue.value = currentValue;
        }

        return updatedValue;
      },
      /**
       * Update the rate basis for either one or all groups.
       *
       * @param {object} selectedRateBasis
       * @param {object} singleGroup
       */
      handleUpdateRateBasis(selectedRateBasis, singleGroup) {
        if (singleGroup) {
          this.$set(singleGroup, 'rate_basis', selectedRateBasis);
          this.onValuesChange(singleGroup);
        } else {
          this.rate.grouped_values.forEach((group, index) => {
            this.$set(this.rate.grouped_values[index], 'parent_rate_basis', selectedRateBasis);
            this.$set(this.rate.grouped_values[index], 'rate_basis', selectedRateBasis);
            this.onValuesChange(group);
          });
        }
      },
      /**
       * Update the values for this group
       *
       * @param {object} group
       */
      onValuesChange(group) {
        const newGroup = {
          ...group,
          values: group.values.map((value) => this.formatValues(value)),
        };

        this.updateInProgress = true;
        this
          .updateRateValues(newGroup)
          .then((data) => {
            this.updateInProgress = false;
            /**
             * Update the store with the new grouped_values
             */
            this.$emit('updateRateAttributeValues', {
              attributeId: data.rate_attribute.id,
              groupedValues: data.rate_attribute.grouped_values,
            });
          })
          .catch(() => {
            this.updateInProgress = false;
            this.displayToast({
              message: 'There was an error updating the rate values.',
            });
          });

        this.$emit('resetValidation');
      },
      /**
       * Update the rate type on this group
       * This means resetting the values on this group and adding new values based on the new rate type
       *
       * @param {object} group
       * @param {string} rateType
       */
      onRateTypeChange(group, rateType) {
        const values = [];
        /* TODO RLH - Revisit to identify if we can make a copy of the updatedGroup without breaking things
      Current code appeases no-param-reassign Linter rule, but is still passing a reference */
        const updatedGroup = group;

        if (rateType === 'AgeBandedRateValue') {
          this.ageBandedValues.forEach((value) => {
            values.push(this.createNewRateValue(value.label, value.displayLabel));
          });
          this.$set(updatedGroup, 'values', values);
        } else {
          values.push(this.createNewRateValue('composite', 'Composite'));
        }
        updatedGroup.type = rateType;
        this.updateRateValues(updatedGroup);
        this.$set(updatedGroup, 'values', values);
      },
      /**
       * Create a blank value object
       *
       * @param {string} label
       * @param {string} displayLabel
       * @returns {object}
       */
      createNewRateValue(label, displayLabel) {
        return {
          label,
          value: null,
          volume: null,
          display_label: displayLabel,
          comparison_flag: 'not_compared',
        };
      },
      /**
       * updates the rate values if there are project product container ids
       *
       * @param {object} group
       * @returns {Promise}
       */
      updateRateValues(group) {
        if (!group.project_products_container_ids.length) {
          return new Promise((resolve) => {
            resolve();
          });
        }

        // TODO this would be a lot more clear if we passed an object with these props
        return ProductService
          .updateRateValues(
            this.selectedProductId,
            this.rate.id,
            group.values,
            group.tier_group_id,
            group.tier_subtype_id,
            group.project_products_container_ids,
            group.rate_basis,
            group.type,
          ).finally(() => {
            this.$emit('resetValidation');
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  h5 {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 7px 10px;
    font: {
      size: 14px;
      weight: 600;
    }
    color: var(--tf-blue);

    .uses-template & {
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding-left: 20px;
    }

    .is-hidden-attribute & {
      color: var(--tf-gray-medium);
    }
  }

  :deep(.hide-button) {
    .fa-eye-slash {
      color: var(--tf-gray-medium);
    }

    .uses-template & {
      margin-right: 5px;
    }
  }

  .table-child {
    margin-left: 20px;
    width: calc(100% - 20px);
    border-left: 1px solid var(--tf-gray-light-medium);
  }

  /* stylelint-disable selector-max-compound-selectors */
  .table td {
    padding: 5px;
    vertical-align: bottom;
    width: 33%;

    &:first-child {
      padding-left: 19px;
    }

    &.rate-type-cell {
      vertical-align: top;
    }

    &:last-child {
      vertical-align: bottom;
    }

    &.align-center {
      vertical-align: middle;
    }

    .el-select + label, .el-select + .rate-basis-container {
      margin-top: 5px;
    }

    .rate-basis-container {
      display: flex;
      align-items: center;

      .el-select {
        width: 110px;
      }

      .app-button {
        margin-left: 4px;
      }
    }

    label {
      display: flex;
      align-items: center;
      height: 26px;
      padding-left: 15px;
    }
  }

  .table-child tbody td:first-child {
    vertical-align: top;
  }
  /* stylelint-enable selector-max-compound-selectors */
  .subtype-select {
    min-width: 144px;
    width: 100%;
  }

  .subtype-read-only-label {
    font-size: 14px;
    min-width: 144px;
    width: 100%;
  }

  .subtype-name {
    min-width: 129px;
  }

  .rate-entry {
    text-align: left;
    border-top: 1px solid var(--tf-gray-light-medium);

    .multi-select-dropdown {
      min-width: 347px;
      margin-right: 20px;
      width: calc(100% - 40px);
    }

    &:last-child .table-child {
      box-shadow: inset 0 -1px 0 0 var(--tf-gray-light-medium);
    }

    :deep(.el-form-item) {
      margin-bottom: 0;

      &.padding-top {
        padding-top: 29px;
      }

      &.is-error.padding-top-with-error {
        padding-top: 29px;
      }

      &.is-error.double-padding-top {
        padding-top: 58px;
      }
    }
    /* stylelint-disable declaration-no-important  */
    :deep(.multi-select-popover) {
      left: auto !important;
      min-width: 343px;
      width: calc(100% - 62px) !important;
    }

    :deep(.el-form-item__error) {
      position: relative;
      top: 0;
      padding-top: 4px !important;
    }

    /* stylelint-enable declaration-no-important  */
    .has-rate-basis {
      & :deep(.el-form-item.double-padding-top) {
        padding-top: 58px;
      }
    }

    .row {
      position: relative;
      min-height: 44px;
      display: flex;
      align-items: center;
      padding: 5px 0;
      box-sizing: border-box;

      &:first-child {
        border-top: 1px solid var(--tf-gray-light-medium);
      }

      &:nth-child(2) {
        box-shadow: inset 0 -1px 0 0 var(--tf-gray-light-medium);
      }

      label {
        font-size: 14px;
        min-width: 74px;
        margin-left: 20px;

        @at-root .is-hidden-attribute {
          color: var(--tf-gray-medium);
        }
      }

      .el-form-item {
        flex-grow: 1;
      }
    }

    .rate-basis-select,
    .disabled-container-select {
      min-width: 347px;
      width: 100%;
      margin-right: 17px;
    }

    .rate-basis-read-only {
      font-size: 14px;
      margin-left: 14px;
      margin-right: 17px;
      min-width: 333px;
      width: 100%;
    }

    .container-read-only-label {
      font-size: 14px;
      margin-left: 14px;
    }

    .ellipsis-button,
    .pencil-button {
      margin-right: 10px;
      margin-left: 14px;
    }
  }

  .rate-type-select {
    min-width: 143px;
    width: 100%;

    &.padding-top {
      margin-top: 29px;
    }
  }

  .rate-value-input {
    min-width: 110px;
    margin-right: 45px;
    width: calc(100% - 37px);
  }

  .read-only-value {
    font-size: 14px;
    display: inline-block;
    height: 26px;
    padding-left: 15px;
  }

  /* stylelint-disable declaration-no-important  */
  .close-more-button.is-icon {
    position: absolute;
    right: -1px;
    top: -23px;
    padding: 3px;
    z-index: 1;
    background-color: var(--tf-base-light) !important;
    border-radius: 4px 4px 0 0;
    border: {
      left: 1px solid var(--tf-blue);
      right: 1px solid var(--tf-blue);
      top: 1px solid var(--tf-blue);
    }
  }
</style>
<style lang="scss">
  .rate-more-popover {
    right: 12px;
    top: 35px;
    box-sizing: border-box;
  }
</style>
